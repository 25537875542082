@charset "UTF-8";
* {
  font-family: "文泉驛正黑","WenQuanYi Zen Hei","儷黑 Pro","LiHei Pro","微軟正黑體","Microsoft JhengHei",DFKai-SB,sans-serif;
}

.glyphicon {
  font-family: "Glyphicons Halflings","文泉驛正黑","WenQuanYi Zen Hei","儷黑 Pro","LiHei Pro","微軟正黑體","Microsoft JhengHei",DFKai-SB,sans-serif;
}

body {
  color: #5d5d5d;
  font-size: 16px;
}

.nolink {
  padding: 14px;
  display: block;
}

@media screen and (max-width: 768px) {
  .nolink {
    padding: 9px 15px;
  }
}

#block-hybridauth-hybridauth {
  position: absolute;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  right: 5px;
  top: 32%;
  z-index: 999;
  opacity: 0.6;
  zoom: 0.7;
}

#block-hybridauth-hybridauth h2 {
  display: inline-block;
  vertical-align: sub;
}

#block-hybridauth-hybridauth .content {
  display: inline-block;
  vertical-align: middle;
}

.hybridauth-widget-wrapper {
  display: inline-block;
}

.not-logged-in #header-top {
  display: none;
}

div.messages, div.messages.status {
  /* Important messages (status, warning, and error) for the user. See also the declarations in messages.css. */
  margin: 1.5em 0;
  /* Drupal core uses "6px 0" margin */
  padding: 3px 3px 3px 59px;
  color: #3f3f3f;
  border: none;
  background: #75d675 url(../images/icon-tick.png) no-repeat 23px center;
}

div.messages ul, div.messages.status ul {
  margin-top: 0;
  margin-bottom: 0;
}

div.warning, div.messages.warning,
tr.warning {
  /* Medium priority messages */
  border-color: #e9c75f;
}

div.error, div.messages.error,
tr.error {
  /* High priority messages. See also the .error declaration below. */
  border-color: #e56464;
}

.error {
  /* Errors that are separate from div.messages status messages. */
  color: #3f3f3f;
}

div.messages.warning, div.warning {
  background: #e9c75f url(../images/icon-warning.png) no-repeat 23px center;
}

div.messages.error, div.error {
  background: #e56464 url(../images/icon-cross.png) no-repeat 23px center;
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  div.messages.status, div.messages {
    background-image: url(../images/icon-tick-2x.png);
    background-size: 16px 16px;
  }
  div.messages.warning, div.warning {
    background-image: url(../images/icon-warning-2x.png);
    background-size: 16px 16px;
  }
  div.messages.error, div.error {
    background-image: url(../images/icon-cross-2x.png);
    background-size: 16px 16px;
  }
}

.messages-inner {
  padding: 15px 20px;
  background: #fff;
}

div.messages.status, div.status,
.ok {
  color: #3f3f3f;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
select:focus,
textarea:focus,
input.form-text:focus,
textarea.form-textarea:focus {
  border-color: #2196F3;
}

#logo img {
  height: 120px;
}

#logo img.print-only {
  display: none;
}

#header {
  padding: 10px 0;
}

#header.sticky {
  padding-bottom: 60px;
}

.auto-refresh, .print-only {
  display: none;
}

#main-navigation {
  background: #ffd699;
}

#main-navigation .navbar-header .nav li a {
  letter-spacing: 0.5px;
}

#main-navigation .navbar-header .nav li.active-trail a, #main-navigation .navbar-header .nav li.active a {
  background: #f1c11e;
}

#main-navigation .navbar-header .nav li > a:hover {
  background: #ff693a;
}

#main-navigation .navbar-header .nav li.dropdown:hover {
  background: #b38c53;
}

#main-navigation .navbar-header .nav li.dropdown.open > a {
  background: #b38c53;
}

#main-navigation .navbar-header .nav li.dropdown.open .dropdown-menu {
  background: #b38c53;
  border-bottom-color: #795548;
}

#main-navigation .navbar-header .nav li.dropdown.open .dropdown-menu a {
  border-bottom-color: #795548;
}

option[disabled="disabled"] {
  color: #d5d5d5;
}

.hide {
  display: none;
}

.remove-padding {
  padding: 0;
}

a:hover {
  text-decoration: none;
}

body .navbar-header .navbar-toggle {
  background: #ff2f00;
}

#breadcrumb {
  padding: 0;
}

#breadcrumb .breadcrumb {
  margin: 5px 0;
}

#main-content {
  padding: 10px 0 20px;
}

tbody tr td {
  vertical-align: middle;
}

tr.even {
  background-color: #DADADA !important;
  border-bottom: 2px solid #F5F5F5;
}

tr.even td {
  background-color: #DADADA !important;
  border-bottom: 2px solid #F5F5F5;
}

tr.odd {
  background-color: #F5F5F5;
  border-bottom: 2px solid #CCC;
}

tr.odd td {
  background-color: #F5F5F5;
  border-bottom: 2px solid #CCC;
}

.path-event .content .field-name-body p {
  font-size: 1.2em;
  letter-spacing: 1px;
  line-height: 1.5;
}

.page-event-result table {
  width: 100%;
}

@media screen and (max-width: 550px) {
  .page-event-result table {
    width: auto;
    position: relative;
    white-space: nowrap;
    display: block;
    overflow-x: auto;
  }
  .page-event-result .views-field-php {
    min-width: 150px;
  }
}

.page-event-result th {
  background-color: #FFF;
  border-bottom: 3px solid #ccc;
}

.page-event-result .view-event-result .view-header h2 {
  font-size: 2em;
  text-align: center;
}

.page-event-result #paying-form {
  padding: 0 20px 20px;
  border: #54B4D2 5px solid;
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.4);
}

.page-event-result .pay-status {
  padding: 15px;
  min-width: 180px;
}

.page-event-result .pay-status .text {
  font-size: 1.2em;
  font-weight: 700;
  color: white;
}

.page-event-result .pay-status .text img {
  display: inline;
}

.page-event-result .pay-status p {
  margin: 0 !important;
}

.page-event-result .pay-status.nopay {
  background: #e56464;
}

.page-event-result .pay-status.nopay .text img.paying_edit {
  padding-left: 10px;
  cursor: pointer;
}

.page-event-result .pay-status.nopay .text img.paying_at_check {
  cursor: pointer;
  background-color: #C5EAA3;
  padding: 5px;
  border-radius: 10px;
  width: 30px;
  height: 30px;
}

.page-event-result .pay-status.checking {
  background: #e9c75f;
}

.page-event-result .pay-status.checking img.paying_edit {
  padding-left: 10px;
  cursor: pointer;
}

.page-event-result .pay-status.checking img.paying_check {
  cursor: pointer;
  background-color: #C5EAA3;
  padding: 5px;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  margin-bottom: -2px;
}

.page-event-result .pay-status.checking .payinfo {
  background: rgba(255, 255, 255, 0.8);
}

.page-event-result .pay-status.payed {
  background: #75d675;
}

.page-event-result .pay-status.payed .payinfo {
  background: rgba(255, 255, 255, 0.8);
}

.path-time tr td:first-child {
  text-align: center;
  border-right: #C7C7C7 solid 5px;
}

.path-time table {
  font-size: 19px;
  border-color: transparent;
}

.path-time tr:nth-child(2n) {
  background-color: #dadada !important;
  border-bottom: 2px solid #f5f5f5;
}

.path-time tr:nth-child(n+1) {
  background-color: #f5f5f5;
  border-bottom: 2px solid #CCC;
}

@media screen and (max-width: 767px) {
  .path-time table {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  #logo img.header--logo {
    width: 25%;
    height: auto;
  }
  #logo img.header--title {
    width: 75%;
    height: auto;
  }
  #main-navigation {
    background: #ffc913;
  }
  #main-navigation .mobile-version-site-name {
    display: none !important;
  }
  #main-navigation .navbar-header .nav li {
    background: #b38c53;
  }
  #main-navigation .navbar-header .nav li a {
    border-bottom: 1px solid #795548;
    letter-spacing: 0.5px;
  }
  #main-navigation .navbar-header .nav li.active-trail a, #main-navigation .navbar-header .nav li.active a {
    background: #753c28;
  }
  #main-navigation .navbar-header .nav li:hover {
    background: #3e2f29;
  }
  .path-event .content .field-name-body p {
    font-size: 1em;
    letter-spacing: 0.5px;
    line-height: 1.2;
  }
}

/* Start: fix bootstrap nav-ber bug on screen 768px http://stackoverflow.com/a/36289507 */
@media (max-width: 991px) {
  .navbar-header {
    float: none;
  }
  .navbar-left, .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in {
    display: block !important;
  }
}

/* End: fix bootstrap nav-ber bug on screen 768px */
@media print {
  body {
    min-width: 1200px;
    background: none;
  }
  html, body, #navigation, #header, #content, #secondary-menu, #page {
    height: auto;
    max-width: 100%;
    overflow: auto;
  }
  .container {
    width: auto;
  }
  #header {
    padding: 0 !important;
  }
  #header > .container > .row > div {
    overflow: hidden;
  }
  h1.page-title {
    text-align: center;
    font-size: 50px;
    margin-top: -20px;
  }
  .view-header {
    visibility: hidden;
  }
  #page-title {
    margin-top: 0;
  }
  thead {
    display: table-header-group;
  }
  input[type="button"], .tabs, .breadcrumb, #header-top {
    display: none;
  }
  a[href]:after {
    content: "";
  }
  body.admin-menu.adminimal-menu:before, #boot-press-to-top {
    display: none !important;
  }
  table {
    margin: 0;
  }
  .block, #main {
    padding: 0;
  }
  .qua-check-1:before {
    content: "" !important;
  }
  #logo img.print-only {
    display: block;
  }
  #logo img.header--logo, #logo img.header--title {
    float: left;
  }
  #logo img.header--sponsor {
    float: right;
    height: 140px;
    margin-top: -20px;
    position: relative;
    top: 12px;
  }
}
